
























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';


@Component({
    components: { },
})

export default class HomeView extends ListBase {

    get user() {
        return this.$store.state.session.user;
    }

    get loading() {
        return this.$store.state.lawyer.loading;
    }

    get isExpired() {
        var date1 = (this as any).$moment(this.user.expiration_date).format("YYYY-MM-DD");
        var date2 = (this as any).$moment().format("YYYY-MM-DD");
        return date2 > date1;
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    async download() {
        let filename = 'AGREMIADO--' + this.user.code + '.pdf';
        let html = await this.$store.dispatch({
            type: this.entity + 'lawyer/download',
            data: {
                id: this.user.id,
                filename: filename,
            }
        });
    }

    async mounted() {
    }

    async created() {

    }
}
